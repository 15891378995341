import React from 'react';

function Footer() {
  return (
    <footer className="app-footer bg-dark text-white py-3">
      <div className="container text-center">
     
      </div>
    </footer>
  );
};

export default Footer;
