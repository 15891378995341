import React from 'react';
import GameGuessTheNumber from '../components/GameGuessTheNumber';
function ViewGuessTheNumber() {
  return (
    <div>
      <GameGuessTheNumber />
    </div>
  );
}

export default ViewGuessTheNumber;
