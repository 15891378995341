import React from 'react';

function About() {
  return (
    <div className="about-page d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <h1>Welcome to About page!</h1>
      </div>
    </div>
  );
}

export default About;
