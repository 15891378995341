import React from "react";
import TodoList from "../components/TodoList";

export default function viewToDoList() {
  return (
    <>
   <TodoList/>
    </>
  );
}
