import React from 'react';
import CrudOperation from '../components/CrudOperation';

function ViewCrudOperation() {
  return (
    <div>
      <CrudOperation />
    </div>
  );
}

export default ViewCrudOperation;
